import './App.css';
import Typed from 'react-typed';
import { useState, useRef } from 'react';
import { Canvas, useFrame } from '@react-three/fiber';
import { Points, PointMaterial } from '@react-three/drei';
import * as random from 'maath/random/dist/maath-random.esm';

function App() {
  return (
    <div className='container'>
      <div id='canvas-container'>
        <Canvas camera={{ position: [0, 0, 1] }}>
          <Stars />
        </Canvas>
      </div>

      <header>
        <h1>
          <span>Georgina </span>
          <span>Puig // </span>
          <Typed
            strings={['Frontend', 'React']}
            typeSpeed={130}
            backSpeed={80}
            backDelay={1500}
            showCursor={true}
            loop
          />
          <span> Developer</span>
        </h1>
      </header>
      <main>
        <nav>
          <ul>
            <li>
              <a
                href='http://linkedin.com/in/georgina-puig/'
                target='_blank'
                rel='noreferrer'
              >
                linkedin
              </a>
            </li>
            <li>
              <a
                href='http://github.com/georginapuig'
                target='_blank'
                rel='noreferrer'
              >
                github
              </a>
            </li>
            <li>
              <a
                href='https://www.instagram.com/georgina.js/'
                target='_blank'
                rel='noreferrer'
              >
                instagram
              </a>
            </li>
            <li>
              <a href='mailto:georginaxpuig@gmail.com'>
                georginaxpuig@gmail.com
              </a>
            </li>
          </ul>
        </nav>
      </main>
    </div>
  );
}

function Stars(props) {
  const ref = useRef();
  const [sphere] = useState(() =>
    random.inSphere(new Float32Array(4000), { radius: 1.2 })
  );
  useFrame((state, delta) => {
    ref.current.rotation.x -= delta / 15;
    ref.current.rotation.y -= delta / 15;
  });
  return (
    <group rotation={[0, 0, Math.PI / 4]}>
      <Points
        ref={ref}
        positions={sphere}
        stride={3}
        frustumCulled={false}
        {...props}
      >
        <PointMaterial
          transparent
          color='#ffe657'
          size={0.004}
          sizeAttenuation={true}
          depthWrite={false}
        />
      </Points>
    </group>
  );
}

export default App;
